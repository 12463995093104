import "./App.css";
// import Graph from "./pages/graph";
// import Banner from "./components/Banner";
import Sidebar from "./components/Sidebar";
// import { Button, Container } from "reactstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Twitter from "./pages/Twitter";
import Github from "./pages/Github";

function App() {
  return (
    <Router>
      {/* <Banner /> */}
      <Sidebar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/twitter" component={Twitter} />
        <Route path="/github" component={Github} />
      </Switch>
      {/* <Graph /> */}
    </Router>
  );
}

export default App;
