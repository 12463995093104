import React from "react";

function Twitter() {
  return (
    <div className="github">
      <h1>Twitter Link</h1>
    </div>
  );
}

export default Twitter;
