import React from "react";

function Github() {
  return (
    <div className="github">
      <h1>Github Link</h1>
    </div>
  );
}

export default Github;
